
import { Injectable } from '@angular/core';

@Injectable()
export class SessionVarsService {

    public sessionCountry:string = "sessionCountry";
    public sessionCategory :string = "sessionCategory";

    public sessionCurrentProjectId:string  = 'sessionCurrentProjectId'
    public sessionCurrentProject:string  = 'sessionCurrentProject'

    public sessionMemoryStructures:string = "sessionMemoryStructures";
    public sessionMemoryStructuresTable:string = "sessionMemoryStructuresTable";
    public sessionRelatedCategories:string = "sessionRelatedCategories";
    

    public sessionConsumerprofile :string = "sessionConsumerprofile";
    public sessionConsumerprofileInput:string = "sessionConsumerprofileInput";
    public sessionConsumerprofileOutput:string  = "sessionConsumerprofileOutput";   
    public sessionConsumerprofileExtended:string = "sessionConsumerprofileExtended";
    public sessionConsumerprofileExtendedJSON = "sessionConsumerprofileExtendedJSON"
    public sessionConsumerPriceSensitivity = "sessionConsumerPriceSensitivity";

    public sessionMemoryStructuresNonconOption = "sessionMemoryStructuresNonconOption"

    public sessionCurrentConsumerProfile:string = "sessionCurrentConsumerProfile";
    
    public sessionIsEnhanced:string  = 'sessionIsEnhanced';
    public sessionProductDesignCount:string = "sessionProductDesignCount";
    public sessionOutputProductDesign:string = "outputProductDesign";

    
    public sessionSelectedProductDescription:string = "sessionSelectedProductDescription";
    public sessionSelectedProductProfile:string = "sessionProductProfile";
    public  sessionOutputEnhancedProductDesign = 'sessionOutputEnhancedProductDesign'
    
    public sessionBrandGuidelines:string = "sessionBrandGuidelines";
    public sessionAdBrandGuidelines:string = "sessionAdBrandGuidelines";
    
    public sessionAdStyle:string = "sessionAdStyle";
    public sessionAdCopy:string  = 'sessionAdCopy';

    public sessionOutputFlavorStagingIngredients:string = "sessionOutputFlavorStagingIngredients";
    public sessionOutputFragranceNotes:string  = 'outputFragranceNotes';
    public sessionOutputFragranceTable:string  = 'outputFragranceTable';   
    public sessionOutputPerfumes:string  = 'sessionOutputPerfumes';
    
    public sessionBrandNames:string = "sessionBrandNames";
    public sessionBrandOutput:string = "sessionBrandOutput";
    public sessionBrandProductRediscription:string = "sessionBrandProductRediscription";
    public sessinoBrandArchetype:string = "sessinoBrandArchetype";
    public sessionBrandImageLinks:string = "sessionBrandImageLinks";
    public sessionBrandBrandGuidelines:string = "sessionBrandBrandGuidelines";

    public  sessionBasisReport:string  = 'sessionBasisReport';
    public  sessionPricing:string  = 'sessionPricing';
    public  sessionWillingness:string  = 'sessionWillingness';
  
}