import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DefaultLayoutComponent } from './containers';
import { ButtonModule, CardModule, FormModule, GridModule } from '@coreui/angular-pro';
import {LoginRoutingModule} from './views/login/login-routing.module';
// import { Page404Component } from './views/pages/page404/page404.component';
// import { Page500Component } from './views/pages/page500/page500.component';
// import { RegisterComponent } from './views/pages/register/register.component';
import { AuthGuard } from './_helpers';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'cp',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'db',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },

      {
        path: 'cp',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/consumerprofile/consumerprofile.module').then((m) => m.ConsumerProfileModule)
      },
      {
        path: 'acp',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/aconsumerprofile/aconsumerprofile.module').then((m) => m.AConsumerProfileModule)
      },
      {
        path: 'ms',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/memorystructures/memorystructures.module').then((m) => m.MemoryStructuresModule)
      },
      {
        path: 'pi',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/productideas/productideas.module').then((m) => m.ProductIdeasModule)
      },
      {
        path: 'ads',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/advertisement/ads/ads.module').then((m) => m.AdsModule)
      },
      {
        path: 'desire',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/desire/desirescore/desire.module').then((m) => m.DesireModule)
      },
      {
        path: 'desirerewrite',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/desire/desirerewrite/desirerewrite.module').then((m) => m.DesireRewriteModule)
      },
      {
        path: 'neuro',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/neuro/analyze/neuroanalyze.module').then((m) => m.NeuroAnalyzeModule)
      },
      {
        path: 'neuroadrewrite',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/neuro/neuroadrewrite/neuroadrewrite.module').then((m) => m.NeuroAdRewriteModule)
      },
      {
        path: 'frd',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/fragrance/fragrancedesign/fragrancedesign.module').then((m) => m.FragranceDesignModule)
      },
      {
        path: 'blend',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/fragrance/fragrancecomposition/fragrancecomposition.module').then((m) => m.FragranceCompositionModule)
      },
      {
        path: 'frs',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/flavor/flavorstaging/flavorstaging.module').then((m) => m.FlavorStagingModule)
      },
      {
        path: 'flc',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/flavor/flavorcomposition/flavorcomposition.module').then((m) => m.FlavorCompositionModule)
      },
      {
        path: 'musicgen',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/music/musicgen/music.module').then((m) => m.MusicModule)
      },
      {
        path: 'musiccompose',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/music/musiccompose/musiccompose.module').then((m) => m.MusicComposeModule)
      },
      {
        path: 'adrewrite',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/advertisement/adrewrite/adrewrite.module').then((m) => m.AdRewriteModule)
      },
      {
        path: 'branddesign',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/brands/branddesign/branddesign.module').then((m) => m.BrandDesignModule)
      },
      // {
      //   path: 'brandimage',canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import('./views/brands/brandimage/brandimage.module').then((m) => m.BrandImageModule)
      // },
      {
        path: 'bl',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/baseline/baseline.module').then((m) => m.BaselineModule)
      },
      {
        path: 'pr',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/pricing/priceline.module').then((m) => m.PricelineModule)
      },
      {
        path: 'pro',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/promotions/promotions.module').then((m) => m.PromotionsModule)
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./views/login/login.module').then((m) => m.LoginModule)
      },
    ]
  },
  // {
  //   path: '404',
  //   component: Page404Component,
  //   data: {
  //     title: 'Page 404'
  //   }
  // },
  // {
  //   path: '500',
  //   component: Page500Component,
  //   data: {
  //     title: 'Page 500'
  //   }
  // },
  { path: '**', redirectTo: 'cp'}
];

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    GridModule,
    FormModule,
    LoginRoutingModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
