import { Component } from '@angular/core';
import { faBrain} from '@fortawesome/free-solid-svg-icons';
import { navItems } from './_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent {

  faBrain = faBrain;
  public navItems = navItems;

  constructor() {}
}
