<div class="bg-light dark:bg-transparent min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col lg="7" md="9" xl="6">
        <c-card class="mx-4">
          <c-card-body class="p-4">
            <form cForm>
              <h1>Register</h1>
              <p class="text-medium-emphasis">Create your account</p>
              <c-input-group class="mb-3">
                <span cInputGroupText>
                  <svg cIcon name="cilUser"></svg>
                </span>
                <input autoComplete="name" cFormControl placeholder="Username" />
              </c-input-group>
              <c-input-group class="mb-3">
                <span cInputGroupText>&#64;</span>
                <input autoComplete="email" cFormControl placeholder="Email" />
              </c-input-group>
              <c-input-group class="mb-3">
                <span cInputGroupText>
                  <svg cIcon name="cilLockLocked"></svg>
                </span>
                <input autoComplete="new-password" cFormControl placeholder="Password" type="password" />
              </c-input-group>
              <c-input-group class="mb-4">
                <span cInputGroupText>
                  <svg cIcon name="cilLockLocked"></svg>
                </span>
                <input autoComplete="new-password" cFormControl placeholder="Repeat password" type="password" />
              </c-input-group>
              <div class="d-grid">
                <button cButton color="success">Create Account</button>
              </div>
            </form>
          </c-card-body>
        </c-card>
      </c-col>
    </c-row>
  </c-container>
</div>
