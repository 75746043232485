import { INavData } from '@coreui/angular-pro';

export const navItems: INavData[] = [
  {
    title: true,
    name: 'Launch Pad',
  },
  {
    name: 'Consumer Profile',
    url: '/cp',
    iconComponent: { name: 'cilPeople' },
    badge: {
      color: 'info',
      text: 'START'
    }

  },
  {
    title: true,
    name: 'Create Product',
  },
  {
    name: 'trendGPT',
    url: '/pi',
    iconComponent: { name: 'cidGraph',color: 'info' }
  },
    
  {
    title: true,
    name: 'Create Advertising',
  },
  {
    name: 'adGPT',
    url: '/ads',
    iconComponent: { name: 'cisTv',color: 'info'},
  },
  {
    title: true,
    name: 'Analyze and Create Desire',
  },
  {
    name: 'Score',
    url: '/desire',
    iconComponent: { name: 'cilHeart' },
  },
  {
    name: 'Rewrite',
    url: '/desirerewrite',
    iconComponent: { name: 'cilPen' },
  },
  {
    title: true,
    name: 'Add Fragrance',
  },
  {
    name: 'Design',
    url: '/frd',
    iconComponent: { name: 'cilBeaker' },
  },
  {
      name: 'Compositions',
      url: '/blend',
      iconComponent: { name: 'cilPen' },
  },
  {
    title: true,
    name: 'Add Flavor', 
  },
  {
    name: 'Staging',
    url: '/frs',
    iconComponent: { name: 'cilRestaurant' },
    // attributes: { disabled: true },
    
  },
  {
    name: 'Cook',
    url: '/flc',
    iconComponent: { name: 'cilDinner' },
    // attributes: { disabled: true },
  },
  {
    title: true,
    name: 'Analyze and Align with Neuroscience',
  },
  {
    name: 'neuroScore',
    url: '/neuro',
    iconComponent: { name: 'cibCreativeCommonsSampling' },
  },
  {
    name: 'Rewrite',
    url: '/neuroadrewrite',
    iconComponent: { name: 'cilPen' },
  },
  {
    title: true,
    name: 'Create Music and Sonic Branding',
  },    
  {
    name: 'Studio',
    url: '/musiccompose',
    iconComponent: { name: 'cisMusicNoteAlt' },
    // attributes: { disabled: true },
  },
  {
    name: 'Compositions',
    url: '/musicgen',
    iconComponent: { name: 'cilMediaPlay' },
    // attributes: { disabled: true },
  },
  {
    title: true,
    name: 'Create a Brand',
  },
  {
    name: 'Brand Book',
    url: '/branddesign',
    iconComponent: { name: 'cidTrademark' },
  },
  {
    title: true,
    name: 'Non-Conscious Pricing',
  },
  {
    name: 'priceGPT',
    url: '/pr',
    iconComponent: { name: 'cilDollar' },
    // attributes: { disabled: true },
  },
  {
    title: true,
    name: 'Baseline',
  },
  {
    name: 'Baseline',
    url: '/bl',
    iconComponent: { name: 'cilSpreadsheet' },
    // attributes: { disabled: true },
  },
  {
    name: 'Promotions',
    url: '/pro',
    iconComponent: { name: 'cilCreditCard' },
    // attributes: { disabled: true },
  },
  {
    title: true,
    name: 'Dashboard',
  },
  {
    name: 'Projects',
    url: '/db',
    iconComponent: { name: 'cilGrid' },
  },
  
];
