<div class="bg-light dark:bg-transparent min-vh-100 d-flex flex-row align-items-center">
  <c-container breakpoint="md">
    <c-row class="justify-content-center">
      <c-col lg="10" xl="4">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form cForm [formGroup]="f" (ngSubmit)="login()">
                <h1>Login</h1>
                <p class="text-medium-emphasis">Sign In to your account</p>
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input autoComplete="username" 
                  cFormControl 
                  formControlName="username" 
                  placeholder="Username"                     
                  [ngClass]="{ 'is-invalid': submitted && f.get('password')?.errors }"
                  />
                  <div *ngIf="submitted && f.get('username')?.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                </div>

                </c-input-group>
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input
                    autoComplete="current-password"
                    cFormControl
                    formControlName="password" 
                    placeholder="Password"
                    type="password"
                    [ngClass]="{ 'is-invalid': submitted && f.get('password')?.errors }"
                  />
                  <div *ngIf="submitted && f.get('password')?.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
                </c-input-group>
                <c-row>
                  <c-col xs="6">
                    <button cButton class="px-4" color="primary" (click)="login()">
                      Login
                    </button>
                  </c-col>
                  <c-col class="text-right" xs="6">
                    <button cButton class="px-0" color="link">
                      Forgot password?
                    </button>
                  </c-col>
                </c-row>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>

              </form>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>
