import { Component,OnInit , inject} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ClassToggleService } from '@coreui/angular-pro';
import { AuthenticationService } from '../../_services';
import { Logger } from 'src/app/shared/service/logger/logger.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  _log = inject(Logger)


  f!: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  classToggler = inject(ClassToggleService)
  

  constructor( private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService
              ) 
{

  // this.classToggler.toggle('body', 'dark-theme');

}
ngOnInit() {
  this.f = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
  });
}


login() {
  console.log('login');
  this.submitted = true;

  // stop here if form is invalid
  if (this.f.invalid) {
      console.log('invalid form');
      return;
  }

  this.error = '';
  this.loading = true;

  console.log('call login');
  this.authenticationService.login(this.f.get('username')?.value, this.f.get('password')?.value)
                        .pipe(first())
                        .subscribe({
                                    next: () => {

                                                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/cp';
                                                this._log.debug(returnUrl);
                                                this._log.debug(returnUrl);

                                                // this.getMe()


                                                this.router.navigate([returnUrl]);
                                              },
                                    error: error => {
                                      this.error = error;
                                      this.loading = false;
                                    }
                                  });
                    }

  getMe(){
    this.authenticationService.getme()
    .pipe(first())
    .subscribe({next: data => {
      // get return url from route parameters or default to '/'
      console.log('MEMEME', data);
      console.log(data);}});

  }
}
