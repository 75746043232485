import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SessionVarsService} from './shared/service/sessionvars.service';

// authentication
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { fakeBackendProvider } from './_helpers';

// Import routing module
import { AppRoutingModule } from './app-routing.module';

// Import app component
import { AppComponent } from './app.component';

// Import containers
import {
        DefaultAsideComponent,
        DefaultFooterComponent,
        DefaultHeaderComponent,
        DefaultLayoutComponent,
      } from './containers';

import {
        AvatarModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonGroupModule,
        ButtonModule,
        CardModule,
        DropdownModule,
        FooterModule,
        FormModule,
        GridModule,
        HeaderModule,
        ListGroupModule,
        NavModule,
        ProgressModule,
        SharedModule,
        SidebarModule,
        TabsModule,
        ModalModule,
        UtilitiesModule,        
      } from '@coreui/angular-pro';

import { IconModule, IconSetService } from '@coreui/icons-angular';

const APP_CONTAINERS = [
                        DefaultAsideComponent,
                        DefaultFooterComponent,
                        DefaultHeaderComponent,
                        DefaultLayoutComponent
                      ];

@NgModule({ declarations: [AppComponent, ...APP_CONTAINERS],
    bootstrap: [AppComponent], imports: [BrowserModule,
                                          BrowserAnimationsModule,
                                          AppRoutingModule,
                                          AvatarModule,
                                          ModalModule,
                                          BreadcrumbModule,
                                          FooterModule,
                                          FormsModule,
                                          DropdownModule,
                                          GridModule,
                                          HeaderModule,
                                          SidebarModule,
                                          IconModule,
                                          NavModule,
                                          ButtonModule,
                                          FormModule,
                                          UtilitiesModule,
                                          ButtonGroupModule,
                                          ReactiveFormsModule,
                                          SidebarModule,
                                          SharedModule,
                                          TabsModule,
                                          ListGroupModule,
                                          ProgressModule,
                                          BadgeModule,
                                          ListGroupModule,
                                          CardModule,
                                          FontAwesomeModule,
                                          NgScrollbarModule], 
                            providers: [SessionVarsService,
                                      {
                                          provide: LocationStrategy,
                                          useClass: HashLocationStrategy
                                      },
                                      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
                                      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
                                      // provider used to create fake backend
                                      fakeBackendProvider,
                                      IconSetService,
                                      Title,
                                      provideHttpClient(withInterceptorsFromDi())
                                  ] })

export class AppModule {


}
